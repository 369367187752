<template>
  <div>
    <div class="gf-card__option">
      <ul class="flex flex--has-space-between">
        <li
          v-for="filter in filters"
          :key="filter.key"
          @click="setFilter(filter.key)"
          :class="filterClasses(filter.key)"
        >
          {{filter.value}}
        </li>
      </ul>
    </div>
    <d3-line-chart
      :options="options"
      :dataset="dataset"
      :chart-id="chartId"
      :singleChart="this.singleChart"
    ></d3-line-chart>
  </div>
</template>

<script>
const D3LineChart = () => import('@/components/chart/D3LineChart.vue');

export default {
  name: 'LineChartCard',
  components: { D3LineChart },
  props: {
    width: Number,
    height: Number,
    dataset: {
      type: Array,
      required: true,
    },
    hour: Boolean,
    day: Boolean,
    week: Boolean,
    doubleweek: Boolean,
    month: Boolean,
    year: Boolean,
    currentFilter: String,
    searchData: Function,
    chartId: String,
    singleChart: Boolean,
  },
  computed: {
    options() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    filters() {
      const filter = [];
      if (this.hour) {
        filter.push({
          key: 'hour',
          value: '1h',
        });
      }
      if (this.day) {
        filter.push({
          key: 'day',
          value: '1d',
        });
      }
      if (this.week) {
        filter.push({
          key: 'week',
          value: '1w',
        });
      }
      if (this.doubleweek) {
        filter.push({
          key: 'doubleweek',
          value: '2w',
        });
      }
      if (this.month) {
        filter.push({
          key: 'month',
          value: '1m',
        });
      }
      if (this.year) {
        filter.push({
          key: 'year',
          value: '1y',
        });
      }
      return filter;
    },
  },
  methods: {
    setFilter(value) {
      // console.log('setFilter', value);
      this.searchData(value);
    },
    filterClasses(key) {
      // console.log('this.singleChart', this.singleChart);
      if (key === this.currentFilter) {
        return 'is-active';
      }
      return '';
    },
    singleChartCheck() {},
  },
};
</script>

<style scoped>

</style>
